
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectInstructionSurcoucheV2',
  props: ['props', 'isHover', 'checkBoxSelected'],
  data: function () {
    return {
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    selectAllInstructionsIO (io: string, $event: Event) {
      this.$emit('ask-select-item-and-kpi', this.props.item.id, this.props.item, !!$event)
    }
  },
  computed: {

  },
  watch: {

  }
})
